

<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                          <router-link to="#" class="btn btn-success mb-2"><i class="mdi mdi-plus-circle mr-1"></i> Add Product</router-link>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    <span class="mr-1">Display</span>
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    <span class="mr-2 d-none d-sm-inline">Search:</span>
                                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>

                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table table-class="table table-centered w-100" thead-tr-class="bg-light" :items="products" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(check)="data">
                                <div class="custom-control custom-checkbox text-center">
                                    <input type="checkbox" class="custom-control-input" :id="`contacusercheck${data.item.id}`" />
                                    <label class="custom-control-label" :for="`contacusercheck${data.item.id}`">{{data.item.id}}</label>
                                </div>
                            </template>
                            <template v-slot:cell(name)="data">
                                <h5 class="m-0 d-inline-block align-middle">
                                    <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.name }}</a>
                                </h5>
                            </template>
                            <template v-slot:cell(price)="data">
                                ${{ data.item.price }}
                            </template>
                            <template v-slot:cell(is_active)="data">
                                <span class="badge badge-soft-success" :class="{'badge-soft-danger': !data.item.is_active}">{{ data.item.is_active ? 'Active' : 'Inactive' }}</span>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
              key: "name",
              sortable: true
          },
          {
              key: "price",
              sortable: true
          },
          {
              key: "is_active",
              label: "Status",
              sortable: true
          },
      ]
    }
  },
  computed: {
    products() {
      return this.$store.state.productList.products
    },
    rows() {
        return this.products.length;
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push(`/products/${id}`).catch(() => {});
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    if(!this.products.length){
     this.$store.dispatch("productList/fetchProducts")
    }
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">

</style>
